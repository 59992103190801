import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const About = () => import(/* webpackChunkName: "home" */'../views/About');
const Productdetails = () => import(/* webpackChunkName: "home" */'../views/Productdetails');
const Myacoount = () => import(/* webpackChunkName: "home" */'../views/My-Account');
const CartLogin = () => import(/* webpackChunkName: "home" */'../views/cart/login');
const Authentication = () => import(/* webpackChunkName: "home" */'../views/cart/Authentication');
const DeliveryOption = () => import(/* webpackChunkName: "home" */'../views/cart/Delivery-Option');
const OrderPlaced = () => import(/* webpackChunkName: "home" */'../views/cart/order-placed');
const Filterpage = () => import(/* webpackChunkName: "home" */'../views/filterpage');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/productdetails/',
            name: 'Productdetails',
            component: Productdetails
        },
        {
            path: '/myaccount/',
            name: 'Myacoount',
            component: Myacoount
        },
        {
            path: '/cart-login/',
            name: 'CartLogin',
            component: CartLogin
        },
        {
            path: '/authentication/',
            name: 'Authentication',
            component: Authentication
        },
        {
            path: '/deliver-option/',
            name: 'DeliveryOption',
            component: DeliveryOption
        },
        {
            path: '/order-placed/',
            name: 'OrderPlaced',
            component: OrderPlaced
        },
        {
            path: '/filterpage/',
            name: 'Filterpage',
            component: Filterpage
        }
    ]
};
